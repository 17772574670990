import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store/index';

import Error from '@/html/subpage/util/Error.vue';

import ParkingMap from '@/html/subpage/parking/ParkingMap.vue';
import openModal from '@/util/modalMessageSetter';

Vue.use(VueRouter);

export const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/',
      redirect: '/main',
    },

    // 메인 페이지
    {
      path: '/main',
      name: 'main',
      component: () => import('@/html/mainpage/main'),
      meta: { category: ['메인', '메인'] },
    },

    /* Util */

    {
      path: '/codinglist',
      name: 'codinglist',
      component: () => import('@/html/guideline/CodingList.vue'),
    },

    // 전체 검색
    {
      path: '/searchlist',
      name: 'searchlist',
      component: () => import('@/html/subpage/util/SearchList.vue'),
    },

    // 로그인
    // 일반 로그인
    // {
    //   path: '/generalmemberlogin',
    //   name: 'generalmemberlogin',
    //   component: () => import('@/html/subpage/util/GeneralMemberLogin'),
    //   meta: {
    //     onlyGuest: true,
    //   },
    // },

    // 통합 회원 로그인
    {
      path: '/ssomemberlogin',
      name: 'ssomemberlogin',
      component: () => import('@/html/subpage/util/SsoMemberLogin'),
      meta: {
        onlyGuest: true,
      },
    },

    // 사업자 로그인
    {
      path: '/businessmemberlogin',
      name: 'businessmemberlogin',
      component: () => import('@/html/subpage/util/BusinessMemberLogin'),
      meta: {
        onlyGuest: true,
      },
    },

    {
      path: '/tokeninfo',
      name: 'tokeninfo',
      component: () => import('@/html/subpage/util/TokenInfo'),
      meta: {
        onlyGuest: true,
      },
    },

    {
      path: '/ssologin',
      name: 'ssologin',
      component: () => import('@/html/subpage/util/SsoLogin'),
      meta: {
        onlyGuest: true,
      },
    },
    {
      path: '/ssologout',
      name: 'ssologout',
      component: () => import('@/html/subpage/util/SsoLogout'),
      meta: {
        empcategory: ['SSO'],
      },
    },
    {
      path: '/ssoerror',
      name: 'ssoerror',
      component: () => import('@/html/subpage/util/SsoError'),
      meta: {
        onlyGuest: true,
      },
    },
    {
      path: '/ssomemberselflogin',
      name: 'ssomemberselflogin',
      component: () => import('@/html/subpage/util/SsoMemberSelfLogin'),
      meta: {
        onlyGuest: true,
      },
    },

    // 회원가입
    // 회원가입 - 일반, 사업자 선택
    {
      path: '/join',
      name: 'join',
      component: () => import('@/html/subpage/util/Join.vue'),
      meta: {
        onlyGuest: true,
      },
    },

    // 약관 동의 - 일반
    // {
    //   path: '/jointermsgeneral',
    //   name: 'jointermsgeneral',
    //   component: () => import('@/html/subpage/util/JoinTermsGeneral.vue'),
    // },

    // 일반 회원가입 - 정보 등록
    // {
    //   path: '/generalinformation',
    //   name: 'generalinformation',
    //   component: () => import('@/html/subpage/util/GeneralInformation.vue'),
    // },

    // 약관 동의 - 사업자
    {
      path: '/jointermsbusiness',
      name: 'jointermsbusiness',
      component: () => import('@/html/subpage/util/JoinTermsBusiness.vue'),
    },

    // 사업자 회원가입 - 사업자 정보 등록
    {
      path: '/businessinformation',
      name: 'businessinformation',
      component: () => import('@/html/subpage/util/BusinessInformation.vue'),
    },

    /* 공영주차장 */

    // 주차장 소개
    {
      path: '/parkinfo',
      name: 'parkinfo',
      component: () => import('@/html/subpage/parking/ParkingInfo.vue'),
      meta: {
        category: ['공영주차장', '주차장 소개'],
      },
    },

    // 주차장 검색
    // 1. 지도검색
    {
      path: '/parkingmap',
      name: 'parkingmap',
      component: () => import('@/html/subpage/parking/ParkingMap.vue'),
      meta: {
        category: ['공영주차장', '주차장 검색', '지도검색'],
      },
    },
    // 2. 주소검색
    {
      path: '/parkinglist',
      name: 'parkinglist',
      component: () => import('@/html/subpage/parking/ParkingAddress.vue'),
      props: true,
      meta: {
        category: ['공영주차장', '주차장 검색', '주소검색'],
      },
    },

    // 주차 요금안내
    {
      path: '/parkfeeinformation',
      name: 'parkfeeinformation',
      component: () => import('@/html/subpage/parking/ParkFeeInformation.vue'),
      meta: {
        category: ['공영주차장', '주차 요금안내'],
      },
    },
    
    //주차요금 감면대상
    {
      path: '/parkingDiscountInfo',
      name: 'parkingDiscountInfo',
      component: () => import('@/html/subpage/parking/ParkingDiscountInfo.vue'),
      meta: {
        category: ['공영주차장', '주차요금 감면대상'],
      },
    },

    /* 주차요금 정산 */

    // 주차요금 사전결제
    // 1. 사전결제 안내
    {
      path: '/prepaymentinfo',
      name: 'prepaymentinfo',
      component: () => import('@/html/subpage/prepayment/PrepaymentInfo.vue'),
      meta: {
        category: ['주차요금 정산', '주차요금 사전결제', '사전결제 안내'],
      },
    },
    // 2. 요금 조회
    {
      path: '/lookup',
      name: 'lookup',
      component: () => import('@/html/subpage/prepayment/LookupPayment.vue'),
      props: true,
      meta: {
        category: ['주차요금 정산', '주차요금 사전결제', '요금 조회/결제'],
      },
    },
    // 3. 요금 상세 및 결제
    {
      path: '/paymentview',
      name: 'paymentview',
      component: () => import('@/html/subpage/prepayment/PaymentView.vue'),
      props: true,
      meta: {
        category: ['주차요금 정산', '주차요금 사전결제', '요금 조회/결제'],
      },
    },
    // 주차요금 간편계산
    {
      path: '/paymentcalc',
      component: () => import('@/html/subpage/prepayment/CalcPayment'),
      name: 'paymentcalc',
      props: true,
      meta: {
        category: ['주차요금 정산', '주차요금 사전결제', '주차요금 간편계산'],
      },
    },

    // 1일/기간 선불 결제
    // 1. 1일/기간 선불 안내
    {
      path: '/dayprepaidinfo',
      name: 'dayprepaidinfo',
      component: () => import('@/html/subpage/dayprepaid/DayPrepaidInfo'),
      props: true,
      meta: {
        category: ['주차요금 정산', '1일/기간 선불 결제', '1일/기간 선불 안내'],
      },
    },
    // 2. 1일 선불 조회 및 결제
    {
      path: '/dayprepaidview',
      name: 'dayprepaidview',
      component: () => import('@/html/subpage/dayprepaid/DayPrepaidView'),
      props: true,
      meta: {
        category: ['주차요금 정산', '1일/기간 선불 결제', '1일 선불 조회/결제'],
      },
    },
    // 3. 기간 선불 조회
    {
      path: '/termprepaidsearch',
      name: 'termprepaidsearch',
      component: () => import('@/html/subpage/dayprepaid/TermPrepaidSearch'),
      props: true,
      meta: {
        category: ['주차요금 정산', '1일/기간 선불 결제', '기간 선불 조회/결제'],
        authorization: ['ROLE_USER', 'ROLE_ADMIN'],
        empcategory: ['USR', 'SSO'],
      },
    },
    // 4. 기간 선불 결제
    {
      path: '/termprepaidview',
      name: 'termprepaidview',
      component: () => import('@/html/subpage/dayprepaid/TermPrepaidView'),
      props: true,
      meta: {
        category: ['주차요금 정산', '1일/기간 선불 결제', '기간 선불 조회/결제'],
        authorization: ['ROLE_USER', 'ROLE_ADMIN'],
        empcategory: ['USR', 'SSO'],
      },
    },
    // 5. 기간 선불 환불
    {
      path: '/termprepaidrefund',
      name: 'termprepaidrefund',
      component: () => import('@/html/subpage/dayprepaid/TermPrepaidRefund'),
      props: true,
      meta: {
        category: ['주차요금 정산', '1일/기간 선불 결제', '기간 선불 환불신청'],
        authorization: ['ROLE_USER', 'ROLE_ADMIN'],
        empcategory: ['USR', 'SSO'],
      },
    },
    // 6. 기간 선불 환불 상세
    {
      path: '/termprepaidrefundview',
      name: 'termprepaidrefundview',
      component: () => import('@/html/subpage/dayprepaid/TermPrepaidRefundView'),
      props: true,
      meta: {
        category: ['주차요금 정산', '1일/기간 선불 결제', '기간 선불 환불신청'],
        authorization: ['ROLE_USER', 'ROLE_ADMIN'],
        empcategory: ['USR', 'SSO'],
      },
    },

    // 미납 주차요금
    // 1. 미납요금 안내
    {
      path: '/unpaidinfo',
      name: 'unpaidinfo',
      component: () => import('@/html/subpage/unpaid/UnpaidInfo.vue'),
      meta: {
        category: ['주차요금 정산', '미납 주차요금', '미납요금 안내'],
      },
    },
    // 2. 미납요금 조회
    {
      path: '/lookupunpaid',
      name: 'lookupunpaid',
      component: () => import('@/html/subpage/unpaid/LookupUnpaid.vue'),
      props: true,
      meta: {
        category: ['주차요금 정산', '미납 주차요금', '미납요금 조회/결제'],
      },
    },
    // 3. 미납요금 상세 및 결제
    {
      path: '/unpaidview',
      name: 'unpaidview',
      component: () => import('@/html/subpage/unpaid/UnpaidView.vue'),
      props: true,
      meta: {
        category: ['주차요금 정산', '미납 주차요금', '미납요금 조회/결제'],
      },
    },

    // /* 요금감면 자격확인 */

    // // 요금 즉시감면 안내
    // {
    //   path: '/reductioninfo',
    //   name: 'reductioninfo',
    //   component: () => import('@/html/subpage/reduction/ReductionInfo.vue'),
    //   meta: {
    //     category: ['요금감면 자격확인', '요금 즉시감면 안내'],
    //   },
    // },

    // // 비대면 자격확인
    // // 1. 동의
    // {
    //   path: '/reductionagreement',
    //   name: 'reductionagreement',
    //   component: () => import('@/html/subpage/reduction/ReductionAgreement.vue'),
    //   meta: {
    //     category: ['요금감면 자격확인', '비대면 자격확인'],
    //   },
    // },
    // // 2. 입력
    // {
    //   path: '/reductionconfirm',
    //   name: 'reductionconfirm',
    //   component: () => import('@/html/subpage/reduction/ReductionConfirm.vue'),
    //   props: true,
    //   meta: {
    //     category: ['요금감면 자격확인', '비대면 자격확인'],
    //   },
    // },
    // // 3. 결과
    // {
    //   path: '/reductionresult',
    //   name: 'reductionresult',
    //   component: () => import('@/html/subpage/reduction/ReductionResult.vue'),
    //   props: true,
    //   meta: {
    //     category: ['요금감면 자격확인', '비대면 자격확인'],
    //   },
    // },

    /* 주차서비스 */

    // 공영주차 정기권
    // 1. 정기권 안내
    {
      path: '/periodinfo',
      name: 'periodinfo',
      component: () => import('@/html/subpage/period/PeriodInfo.vue'),
      meta: {
        category: ['주차서비스', '공영주차 정기권', '정기권 안내'],
      },
    },
    // 2. 정기권 신청 정보입력
    {
      path: '/periodapplication',
      name: 'periodapplication',
      component: () => import('@/html/subpage/period/PeriodApplication.vue'),
      meta: {
        category: ['주차서비스', '공영주차 정기권', '정기권 신청'],
        authorization: ['ROLE_USER', 'ROLE_ADMIN'],
        empcategory: ['USR', 'SSO'],
      },
    },
    // 3. 정기권 조회
    {
      path: '/periodlookup',
      name: 'periodlookup',
      component: () => import('@/html/subpage/period/PeriodLookup.vue'),
      props: true,
      meta: {
        category: ['주차서비스', '공영주차 정기권', '정기권 조회/결제'],
        authorization: ['ROLE_USER', 'ROLE_ADMIN'],
        empcategory: ['USR', 'SSO'],
      },
    },
    // 4. 졍기권 상세 및 결제
    {
      path: '/periodview',
      name: 'periodview',
      component: () => import('@/html/subpage/period/PeriodView.vue'),
      props: true,
      meta: {
        category: ['주차서비스', '공영주차 정기권', '정기권 신청'],
        authorization: ['ROLE_USER', 'ROLE_ADMIN'],
        empcategory: ['USR', 'SSO'],
      },
    },
    // 5. 정기권 해지
    {
      path: '/periodcancel',
      name: 'periodcancel',
      component: () => import('@/html/subpage/period/PeriodCancel.vue'),
      props: true,
      meta: {
        category: ['주차서비스', '공영주차 정기권', '정기권 해지'],
        authorization: ['ROLE_USER', 'ROLE_ADMIN'],
        empcategory: ['USR', 'SSO'],
      },
    },
    // 6. 정기권 해지 상세
    {
      path: '/periodcancelview',
      name: 'periodcancelview',
      component: () => import('@/html/subpage/period/PeriodCancelView.vue'),
      props: true,
      meta: {
        category: ['주차서비스', '공영주차 정기권', '정기권 해지'],
        authorization: ['ROLE_USER', 'ROLE_ADMIN'],
        empcategory: ['USR', 'SSO'],
      },
    },
    // 7. 정기권 약관 동의
    {
      path: '/periodjointerms',
      component: () => import('@/html/subpage/period/PeriodJoinTerms.vue'),
      meta: {
        category: ['주차서비스', '공영주차 정기권', '정기권 신청'],
        authorization: ['ROLE_USER', 'ROLE_ADMIN'],
        empcategory: ['USR', 'SSO'],
      },
    },

    // 무정차 정산
    // 1. 안내
    {
      path: '/nonstopinfo',
      name: 'nonstopinfo',
      component: () => import('@/html/subpage/nonstop/NonstopInfo.vue'),
      meta: {
        category: ['주차서비스', '무정차 정산', '무정차 정산 안내'],
      },
    },
    // 2. 신청
    {
      path: '/nonstopapplication',
      name: 'nonstopapplication',
      component: () => import('@/html/subpage/nonstop/NonstopApplication.vue'),
      meta: {
        category: ['주차서비스', '무정차 정산', '무정차 정산 신청'],
        authorization: ['ROLE_USER', 'ROLE_ADMIN'],
        empcategory: ['USR', 'SSO'],
      },
    },
    // 3. 결제카드 등록
    {
      path: '/nonstopcardregist',
      name: 'nonstopcardregist',
      component: () => import('@/html/subpage/nonstop/NonstopCardRegist.vue'),
      props: true,
      meta: {
        category: ['주차서비스', '무정차 정산', '무정차 정산 신청'],
        authorization: ['ROLE_USER', 'ROLE_ADMIN'],
        empcategory: ['USR', 'SSO'],
      },
    },
    // 4. 무정차 차량 등록
    {
      path: '/nonstopcarregist',
      name: 'nonstopcarregist',
      component: () => import('@/html/subpage/nonstop/NonstopCarRegist.vue'),
      props: true,
      meta: {
        category: ['주차서비스', '무정차 정산', '무정차 정산 신청'],
        authorization: ['ROLE_USER', 'ROLE_ADMIN'],
        empcategory: ['USR', 'SSO'],
      },
    },

    // 웹 할인
    // 1. 웹 할인 안내
    {
      path: '/webdiscountinfo',
      name: 'webdiscountinfo',
      component: () => import('@/html/subpage/webDiscount/WebDiscountInfo.vue'),
      meta: {
        category: ['주차서비스', '웹 할인', '웹할인 안내'],
      },
    },
    // 2. 웹 할인 충전
    {
      path: '/webdiscountpay',
      name: 'webdiscountpay',
      component: () => import('@/html/subpage/webDiscount/WebDiscountPay.vue'),
      meta: {
        category: ['주차서비스', '웹 할인', '웹할인 충전'],
        authorization: ['ROLE_STORE', 'ROLE_ADMIN'],
        empcategory: ['BSN'],
      },
    },
    // 3. 웹 할인 적용 - 카드
    {
      path: '/webdiscountusecard',
      name: 'webdiscountusecard',
      component: () => import('@/html/subpage/webDiscount/WebDiscountUseCard.vue'),
      meta: {
        category: ['주차서비스', '웹 할인', '웹할인 적용'],
        authorization: ['ROLE_STORE', 'ROLE_ADMIN'],
        empcategory: ['BSN'],
      },
    },
    // 4. 웹 할인 적용 - 포인트
    {
      path: '/webdiscountusepoint',
      name: 'webdiscountusepoint',
      component: () => import('@/html/subpage/webDiscount/WebDiscountUsePoint.vue'),
      meta: {
        category: ['주차서비스', '웹 할인', '웹할인 적용'],
        authorization: ['ROLE_STORE', 'ROLE_ADMIN'],
        empcategory: ['BSN'],
      },
    },
    // 5. 웹 할인 적용 - 1일 선불권
    {
      path: '/webdiscountuseprepaid',
      name: 'webdiscountuseprepaid',
      component: () => import('@/html/subpage/webDiscount/WebDiscountUsePrepaid'),
      meta: {
        category: ['주차서비스', '웹 할인', '웹할인 적용'],
        authorization: ['ROLE_STORE', 'ROLE_ADMIN'],
        empcategory: ['BSN'],
      },
    },
    // 6. 웹 할인 적용 - 전통시장
    {
      path: '/webdiscountusemarket',
      name: 'webdiscountusemarket',
      component: () => import('@/html/subpage/webDiscount/WebDiscountUseMarket'),
      meta: {
        category: ['주차서비스', '웹 할인', '웹할인 적용'],
        authorization: ['ROLE_STORE', 'ROLE_ADMIN'],
        empcategory: ['BSN'],
      },
    },

    /* 정보마당 */

    // 공지사항
    {
      path: '/noticelist',
      name: 'noticelist',
      component: () => import('@/html/subpage/board/NoticeList.vue'),
      meta: {
        category: ['정보마당', '공지사항'],
      },
    },
    // 공지사항 상세
    {
      path: '/noticeview',
      name: 'noticeview',
      component: () => import('@/html/subpage/board/NoticeView.vue'),
      meta: {
        category: ['정보마당', '공지사항'],
      },
    },

    // 묻고 답하기
    {
      path: '/qna',
      name: 'qna',
      component: () => {
        const link = 'https://www.auc.or.kr/base/board/list?boardManagementNo=1&menuLevel=2&menuNo=1';
        if (/Mobi/i.test(window.navigator.userAgent)) {
          location.href = link;
        } else {
          window.open(link, '_blank');
        }
      },
      meta: {
        category: ['정보마당', '묻고 답하기'],
      },
    },

    // 자주묻는 질문
    {
      path: '/faqview',
      name: 'faqview',
      component: () => import('@/html/subpage/board/FaqView.vue'),
      meta: {
        category: ['정보마당', '자주묻는 질문'],
      },
    },

    /* 마이페이지(일반) */

    // 비밀번호 확인
    {
      path: '/mypagecheck',
      name: 'mypagecheck',
      component: () => import('@/html/subpage/mypage/MypageCheck.vue'),
      meta: {
        category: ['마이페이지', '회원정보'],
        authorization: ['ROLE_USER', 'ROLE_STORE', 'ROLE_ADMIN'],
        empcategory: ['USR', 'BSN', 'SSO'],
      },
    },

    // 1. 나의정보
    {
      path: '/mypagegeneral',
      name: 'mypagegeneral',
      component: () => import('@/html/subpage/mypage/MypageGeneral.vue'),
      meta: {
        category: ['마이페이지', '회원정보'],
        authorization: ['ROLE_USER', 'ROLE_ADMIN'],
        empcategory: ['USR', 'SSO'],
      },
    },
    // 2. 나의정보 - 수정
    {
      path: '/mypagegeneralupdate',
      name: 'mypagegeneralupdate',
      component: () => import('@/html/subpage/mypage/MypageGeneralUpdate'),
      meta: {
        category: ['마이페이지', '회원정보'],
        authorization: ['ROLE_USER', 'ROLE_ADMIN'],
        empcategory: ['USR', 'SSO'],
      },
    },

    // 1. 일반할인 신청
    {
      path: '/mypagegeneraldiscount',
      name: 'mypagegeneraldiscount',
      component: () => import('@/html/subpage/mypage/MypageGeneralDiscount.vue'),
      meta: {
        category: ['마이페이지', '일반할인 신청'],
        authorization: ['ROLE_USER', 'ROLE_ADMIN'],
        empcategory: ['USR', 'SSO'],
      },
    },
    // 2. 일반할인 신청 - 약관
    {
      path: '/mypagegeneraldiscount/agreement',
      name: 'mypagegeneraldiscountagreement',
      component: () => import('@/html/subpage/mypage/MypageGeneralDiscountAgreement'),
      props: true,
      meta: {
        category: ['마이페이지', '일반할인 신청'],
        authorization: ['ROLE_USER', 'ROLE_ADMIN'],
        empcategory: ['USR', 'SSO'],
      },
    },
    // 3. 일반할인 신청 - 정보입력
    {
      path: '/mypagegeneraldiscount/enrollment',
      name: 'mypagegeneraldiscountenrollment',
      component: () => import('@/html/subpage/mypage/MypageGeneralDiscountEnrollment'),
      props: true,
      meta: {
        category: ['마이페이지', '일반할인 신청'],
        authorization: ['ROLE_USER', 'ROLE_ADMIN'],
        empcategory: ['USR', 'SSO'],
      },
    },
    // 4. 일반할인 신청 - 신청확인
    {
      path: '/mypagegeneraldiscount/result',
      name: 'mypagegeneraldiscountresult',
      component: () => import('@/html/subpage/mypage/MypageGeneralDiscountResult'),
      props: true,
      meta: {
        category: ['마이페이지', '일반할인 신청'],
        authorization: ['ROLE_USER', 'ROLE_ADMIN'],
        empcategory: ['USR', 'SSO'],
      },
    },

    // 1. 서비스이용내역 - 정기권
    {
      path: '/mypagegeneralpayment',
      name: 'mypagegeneralpayment',
      component: () => import('@/html/subpage/mypage/MypageGeneralPayment.vue'),
      meta: {
        category: ['마이페이지', '서비스 이용내역'],
        authorization: ['ROLE_USER', 'ROLE_ADMIN'],
        empcategory: ['USR', 'SSO'],
      },
    },
    // 2. 서비스이용내역 - 무정차
    {
      path: '/mypagegeneralnonstop',
      name: 'mypagegeneralnonstop',
      component: () => import('@/html/subpage/mypage/MypageGeneralNonstop.vue'),
      meta: {
        category: ['마이페이지', '서비스 이용내역'],
        authorization: ['ROLE_USER', 'ROLE_ADMIN'],
        empcategory: ['USR', 'SSO'],
      },
    },
    // 3. 서비스이용내역 - 미납요금
    {
      path: '/mypagegeneralunpaid',
      name: 'mypagegeneralunpaid',
      component: () => import('@/html/subpage/mypage/MypageGeneralUnpaid.vue'),
      meta: {
        category: ['마이페이지', '서비스 이용내역'],
        authorization: ['ROLE_USER', 'ROLE_ADMIN'],
        empcategory: ['USR', 'SSO'],
      },
    },

    /* 마이페이지(사업자) */

    // 1. 사업자정보
    {
      path: '/mypagebusiness',
      name: 'mypagebusiness',
      component: () => import('@/html/subpage/mypage/MypageBusiness.vue'),
      meta: {
        category: ['마이페이지', '회원정보'],
        authorization: ['ROLE_STORE', 'ROLE_ADMIN'],
        empcategory: ['BSN'],
      },
    },
    // 2. 사업자정보 - 수정
    {
      path: '/mypagebusinessupdate',
      name: 'mypagebusinessupdate',
      component: () => import('@/html/subpage/mypage/MypageBusinessUpdate'),
      meta: {
        category: ['마이페이지', '회원정보'],
        authorization: ['ROLE_STORE', 'ROLE_ADMIN'],
        empcategory: ['BSN'],
      },
    },

    // 1. 서비스 이용내역 - 카드 결제내역
    {
      path: '/mypagebusinessusecard',
      name: 'mypagebusinessusecard',
      component: () => import('@/html/subpage/mypage/MypageBusinessUseCard'),
      meta: {
        category: ['마이페이지', '서비스 이용내역'],
        authorization: ['ROLE_STORE', 'ROLE_ADMIN'],
        empcategory: ['BSN'],
      },
    },
    // 2.서비스 이용내역 - 포인트 이용내역
    // 2-1. 포인트 구매내역
    {
      path: '/mypagebusinessbuypoint',
      name: 'mypagebusinessbuypoint',
      component: () => import('@/html/subpage/mypage/MypageBusinessBuyPoint'),
      meta: {
        category: ['마이페이지', '서비스 이용내역'],
        authorization: ['ROLE_STORE', 'ROLE_ADMIN'],
        empcategory: ['BSN'],
      },
    },
    // 2-2. 포인트 사용내역
    {
      path: '/mypagebusinessusepoint',
      name: 'mypagebusinessusepoint',
      component: () => import('@/html/subpage/mypage/MypageBusinessUsePoint'),
      meta: {
        category: ['마이페이지', '서비스 이용내역'],
        authorization: ['ROLE_STORE', 'ROLE_ADMIN'],
        empcategory: ['BSN'],
      },
    },
    // 3. 서비스 이용내역 - 할인적용 내역
    {
      path: '/mypagebusinessdiscount',
      name: 'mypagebusinessdiscount',
      component: () => import('@/html/subpage/mypage/MypageBusinessDiscount'),
      meta: {
        category: ['마이페이지', '서비스 이용내역'],
        authorization: ['ROLE_STORE', 'ROLE_ADMIN'],
        empcategory: ['BSN'],
      },
    },

    /* 관리자 메뉴 */

    // 관리자 로그인
    {
      path: '/admin',
      name: 'AdminLogin',
      component: () => import('@/html/manager/util/AdminLogin.vue'),
    },

    // 회원관리
    // 1. 일반회원
    {
      path: '/generalmembership',
      name: 'generalmembership',
      component: () => import('@/html/manager/member/GeneralMembership.vue'),
      meta: {
        category: ['관리자메뉴', '회원관리', '일반회원'],
        authorization: ['ROLE_ADMIN'],
      },
    },
    // 1-1. 일반회원 상세
    {
      path: '/generalmembershipview',
      name: 'generalmembershipview',
      component: () => import('@/html/manager/member/GeneralMembershipView.vue'),
      meta: {
        category: ['관리자메뉴', '회원관리', '일반회원'],
        authorization: ['ROLE_ADMIN'],
      },
    },
    // 2. 사업자회원
    {
      path: '/businessmembership',
      name: 'businessmembership',
      component: () => import('@/html/manager/member/BusinessMembership.vue'),
      meta: {
        category: ['관리자메뉴', '회원관리', '사업자회원'],
        authorization: ['ROLE_ADMIN'],
      },
    },
    // 2-2. 사업자회원 상세
    {
      path: '/businessmembershipview',
      name: 'businessmembershipview',
      component: () => import('@/html/manager/member/BusinessMembershipView.vue'),
      meta: {
        category: ['관리자메뉴', '회원관리', '사업자회원'],
        authorization: ['ROLE_ADMIN'],
      },
    },

    // 할인차량 관리
    // 1. 할인차량 관리
    {
      path: '/discountvehicle',
      name: 'discountvehicle',
      component: () => import('@/html/manager/vehicle/DiscountVehicle.vue'),
      meta: {
        category: ['관리자메뉴', '할인차량 관리', '할인차량 관리'],
        authorization: ['ROLE_ADMIN'],
      },
    },
    // 2. 할인차량 관리 상세
    {
      path: '/discountvehicleview',
      name: 'discountvehicleview',
      component: () => import('@/html/manager/vehicle/DiscountVehicleView.vue'),
      meta: {
        category: ['관리자메뉴', '할인차량 관리', '할인차량 관리'],
        authorization: ['ROLE_ADMIN'],
      },
    },
    // 3. 할인차량 등록
    {
      path: '/discountvehicleenrollment',
      name: 'discountvehicleenrollment',
      component: () => import('@/html/manager/vehicle/DiscountVehicleEnrollment.vue'),
      meta: {
        category: ['관리자메뉴', '할인차량 관리', '할인차량 관리'],
        authorization: ['ROLE_ADMIN'],
      },
    },
    // 4. 할인차량 수정
    {
      path: '/discountvehicleupdate',
      name: 'discountvehicleupdate',
      component: () => import('@/html/manager/vehicle/DiscountVehicleUpdate.vue'),
      meta: {
        category: ['관리자메뉴', '할인차량 관리', '할인차량 관리'],
        authorization: ['ROLE_ADMIN'],
      },
    },
    // 1. 무료차량 관리
    {
      path: '/freevehicle',
      name: 'freevehicle',
      component: () => import('@/html/manager/vehicle/FreeVehicle.vue'),
      meta: {
        category: ['관리자메뉴', '할인차량 관리', '무료차량 관리'],
        authorization: ['ROLE_ADMIN'],
      },
    },
    // 2. 무료차량 상세
    {
      path: '/freevehicleview',
      name: 'freevehicleview',
      component: () => import('@/html/manager/vehicle/FreeVehicleView.vue'),
      meta: {
        category: ['관리자메뉴', '할인차량 관리', '무료차량 관리'],
        authorization: ['ROLE_ADMIN'],
      },
    },
    // 3. 무료차량 등록
    {
      path: '/freevehicleenrollment',
      name: 'freevehicleenrollment',
      component: () => import('@/html/manager/vehicle/FreeVehicleEnrollment.vue'),
      meta: {
        category: ['관리자메뉴', '할인차량 관리', '무료차량 관리'],
        authorization: ['ROLE_ADMIN'],
      },
    },
    // 4. 무료차량 수정
    {
      path: '/freevehicleupdate',
      name: 'freevehicleupdate',
      component: () => import('@/html/manager/vehicle/FreeVehicleUpdate.vue'),
      meta: {
        category: ['관리자메뉴', '할인차량 관리', '무료차량 관리'],
        authorization: ['ROLE_ADMIN'],
      },
    },

    // 웹할인 할인율 관리
    {
      path: '/webdiscountrate',
      name: 'webdiscountrate',
      component: () => import('@/html/manager/webdiscount/webDiscountRate.vue'),
      meta: {
        category: ['관리자메뉴', '웹할인 할인율 관리'],
        authorization: ['ROLE_ADMIN'],
      },
    },

    // 정기권 주차장 관리
    {
      path: '/periodparking',
      name: 'periodparking',
      component: () => import('@/html/manager/period/PeriodParking.vue'),
      meta: {
        category: ['관리자메뉴', '정기권주차장 관리'],
        authorization: ['ROLE_ADMIN'],
      },
    },

    // 게시판 관리
    // 1. 공지사항 관리 조회
    {
      path: '/noticemanagement',
      name: 'noticemanagement',
      component: () => import('@/html/manager/notice/NoticeManagement.vue'),
      meta: {
        category: ['관리자메뉴', '게시판 관리', '공지사항 관리'],
        authorization: ['ROLE_ADMIN'],
      },
    },
    // 2. 공지사항 관리 상세
    {
      path: '/noticemanagementview',
      name: 'noticemanagementview',
      component: () => import('@/html/manager/notice/NoticeManagementView.vue'),
      meta: {
        category: ['관리자메뉴', '게시판 관리', '공지사항 관리'],
        authorization: ['ROLE_ADMIN'],
      },
    },
    // 3. 공지사항 관리 등록
    {
      path: '/noticemanagementenrollment',
      name: 'noticemanagementenrollment',
      component: () => import('@/html/manager/notice/NoticeManagementEnrollment.vue'),
      meta: {
        category: ['관리자메뉴', '게시판 관리', '공지사항 관리'],
        authorization: ['ROLE_ADMIN'],
      },
    },
    // 4. 공지사항 관리 수정
    {
      path: '/noticemanagementupdate',
      name: 'noticemanagementupdate',
      component: () => import('@/html/manager/notice/NoticeManagementUpdate.vue'),
      meta: {
        category: ['관리자메뉴', '게시판 관리', '공지사항 관리'],
        authorization: ['ROLE_ADMIN'],
      },
    },

    // 1. 자주하는 질문 관리 조회
    {
      path: '/faqmanagement',
      name: 'faqmanagement',
      component: () => import('@/html/manager/faq/FaQManagement.vue'),
      meta: {
        category: ['관리자메뉴', '게시판 관리', '자주묻는 질문 관리'],
        authorization: ['ROLE_ADMIN'],
      },
    },
    // 2. 자주하는 질문 관리 상세
    {
      path: '/faqmanagementview',
      name: 'faqmanagementview',
      component: () => import('@/html/manager/faq/FaQManagementView.vue'),
      meta: {
        category: ['관리자메뉴', '게시판 관리', '자주묻는 질문 관리'],
        authorization: ['ROLE_ADMIN'],
      },
    },
    // 3. 자주하는 질문 관리 등록
    {
      path: '/faqmanagementenrollment',
      name: 'faqmanagementenrollment',
      component: () => import('@/html/manager/faq/FaQManagementEnrollment.vue'),
      meta: {
        category: ['관리자메뉴', '게시판 관리', '자주묻는 질문 관리'],
        authorization: ['ROLE_ADMIN'],
      },
    },
    // 4. 자주하는 질문 관리 수정
    {
      path: '/faqmanagementupdate',
      name: 'faqmanagementupdate',
      component: () => import('@/html/manager/faq/FaQManagementUpdate.vue'),
      meta: {
        category: ['관리자메뉴', '게시판 관리', '자주묻는 질문 관리'],
        authorization: ['ROLE_ADMIN'],
      },
    },

    // 팝업/배너 관리
    // 1. 팝업 관리 조회
    {
      path: '/popuplist',
      name: 'popuplist',
      component: () => import('@/html/manager/popup/PopupList.vue'),
      meta: {
        category: ['관리자메뉴', '팝업/배너 관리', '팝업 관리'],
        authorization: ['ROLE_ADMIN'],
      },
    },
    // 2. 팝업 관리 상세
    {
      path: '/popupview',
      name: 'popupview',
      component: () => import('@/html/manager/popup/PopupView.vue'),
      meta: {
        category: ['관리자메뉴', '팝업/배너 관리', '팝업 관리'],
        authorization: ['ROLE_ADMIN'],
      },
    },
    // 3. 팝업 관리 등록
    {
      path: '/popupenrollment',
      name: 'popupenrollment',
      component: () => import('@/html/manager/popup/PopupEnrollment.vue'),
      meta: {
        category: ['관리자메뉴', '팝업/배너 관리', '팝업 관리'],
        authorization: ['ROLE_ADMIN'],
      },
    },
    // 4. 팝업 관리 수정
    {
      path: '/popupupdate',
      name: 'popupupdate',
      component: () => import('@/html/manager/popup/PopupUpdate.vue'),
      meta: {
        category: ['관리자메뉴', '팝업/배너 관리', '팝업 관리'],
        authorization: ['ROLE_ADMIN'],
      },
    },

    // 1. 배너 관리 조회
    {
      path: '/bannerlist',
      component: () => import('@/html/manager/banner/BannerList.vue'),
      meta: {
        category: ['관리자메뉴', '팝업/배너 관리', '배너 관리'],
        authorization: ['ROLE_ADMIN'],
      },
    },
    // 2. 배너 관리 상세
    {
      path: '/bannerview',
      name: 'bannerview',
      component: () => import('@/html/manager/banner/BannerView.vue'),
      props: true,
      meta: {
        category: ['관리자메뉴', '팝업/배너 관리', '배너 관리'],
        authorization: ['ROLE_ADMIN'],
      },
    },
    // 3. 배너 관리 등록
    {
      path: '/bannerenrollment',
      name: 'bannerenrollment',
      component: () => import('@/html/manager/banner/BannerEnrollment.vue'),
      props: true,
      meta: {
        category: ['관리자메뉴', '팝업/배너 관리', '배너 관리'],
        authorization: ['ROLE_ADMIN'],
      },
    },
    // 4. 배너 관리 수정
    {
      path: '/bannerupdate',
      component: () => import('@/html/manager/banner/BannerUpdate.vue'),
      name: 'bannerupdate',
      meta: {
        category: ['관리자메뉴', '팝업/배너 관리', '배너 관리'],
        authorization: ['ROLE_ADMIN'],
      },
      props: true,
    },

    /* 시스템 관리 */

    // 1. 관리자 관리 조회
    {
      path: '/usermanagement',
      name: 'usermanagement',
      component: () => import('@/html/system/user/UserManagement.vue'),
      meta: {
        category: ['시스템관리', '관리자관리'],
        authorization: ['ROLE_ADMIN'],
      },
    },
    // 2. 관리자 관리 상세
    {
      path: '/userview',
      name: 'userview',
      component: () => import('@/html/system/user/UserView.vue'),
      meta: {
        category: ['시스템관리', '관리자관리'],
        authorization: ['ROLE_ADMIN'],
      },
    },
    // 3. 관리자 관리 등록
    {
      path: '/userenrollment',
      name: 'userenrollment',
      component: () => import('@/html/system/user/UserEnrollment.vue'),
      meta: {
        category: ['시스템관리', '관리자관리'],
        authorization: ['ROLE_ADMIN'],
      },
    },
    // 4. 관리자 관리 수정
    {
      path: '/userupdate',
      name: 'userupdate',
      component: () => import('@/html/system/user/UserUpdate.vue'),
      meta: {
        category: ['시스템관리', '관리자관리'],
        authorization: ['ROLE_ADMIN'],
      },
    },

    // 1. 부서관리 조회
    {
      path: '/departmentlist',
      name: 'departmentlist',
      component: () => import('@/html/system/department/DepartmentList.vue'),
      meta: {
        category: ['시스템관리', '부서관리'],
        authorization: ['ROLE_ADMIN'],
      },
    },
    // 2. 부서관리 상세
    {
      path: '/departmentview',
      name: 'departmentview',
      component: () => import('@/html/system/department/DepartmentView.vue'),
      meta: {
        category: ['시스템관리', '부서관리'],
        authorization: ['ROLE_ADMIN'],
      },
    },
    // 3. 부서관리 등록
    {
      path: '/departmentenrollment',
      name: 'departmentenrollment',
      component: () => import('@/html/system/department/DepartmentEnrollment.vue'),
      meta: {
        category: ['시스템관리', '부서관리'],
        authorization: ['ROLE_ADMIN'],
      },
    },
    // 4. 부서관리 수정
    {
      path: '/departmentupdate',
      name: 'departmentupdate',
      component: () => import('@/html/system/department/DepartmentUpdate.vue'),
      meta: {
        category: ['시스템관리', '부서관리'],
        authorization: ['ROLE_ADMIN'],
      },
    },

    // 1. 팀관리 조회
    {
      path: '/teamlist',
      name: 'teamlist',
      component: () => import('@/html/system/team/TeamList.vue'),
      meta: {
        category: ['시스템관리', '팀관리'],
        authorization: ['ROLE_ADMIN'],
      },
    },
    // 2. 팀관리 상세
    {
      path: '/teamview',
      name: 'teamview',
      component: () => import('@/html/system/team/TeamView.vue'),
      meta: {
        category: ['시스템관리', '팀관리'],
        authorization: ['ROLE_ADMIN'],
      },
    },
    // 3. 팀관리 등록
    {
      path: '/teamenrollment',
      name: 'teamenrollment',
      component: () => import('@/html/system/team/TeamEnrollment.vue'),
      meta: {
        category: ['시스템관리', '팀관리'],
        authorization: ['ROLE_ADMIN'],
      },
    },
    // 4. 팀관리 수정
    {
      path: '/teamupdate',
      name: 'teamupdate',
      component: () => import('@/html/system/team/TeamUpdate.vue'),
      meta: {
        category: ['시스템관리', '팀관리'],
        authorization: ['ROLE_ADMIN'],
      },
    },

    // 1. 메뉴관리 조회
    {
      path: '/menulist',
      name: 'menulist',
      component: () => import('@/html/system/menu/MenuList.vue'),
      meta: {
        category: ['시스템관리', '메뉴관리'],
        authorization: ['ROLE_ADMIN'],
      },
    },
    // 2. 메뉴관리 상세
    {
      path: '/menuview',
      name: 'menuview',
      component: () => import('@/html/system/menu/MenuView.vue'),
      meta: {
        category: ['시스템관리', '메뉴관리'],
        authorization: ['ROLE_ADMIN'],
      },
    },
    // 3. 메뉴관리 등록
    {
      path: '/menuenrollment',
      name: 'menuenrollment',
      component: () => import('@/html/system/menu/MenuEnrollment.vue'),
      meta: {
        category: ['시스템관리', '메뉴관리'],
        authorization: ['ROLE_ADMIN'],
      },
    },
    // 4. 메뉴관리 수정
    {
      path: '/menuupdate',
      name: 'menuupdate',
      component: () => import('@/html/system/menu/MenuUpdate.vue'),
      meta: {
        category: ['시스템관리', '메뉴관리'],
        authorization: ['ROLE_ADMIN'],
      },
    },

    // 관리자 권한 관리
    {
      path: '/userauthoritylist',
      name: 'userauthoritylist',
      component: () => import('@/html/system/authority/UserAuthorityList.vue'),
      meta: {
        category: ['시스템관리', '사용자권한관리'],
        authorization: ['ROLE_STORE', 'ROLE_ADMIN'],
      },
    },

    // 관리자 접속정보
    {
      path: '/accessinfo',
      name: 'accessinfo',
      component: () => import('@/html/system/access/AccessInfo.vue'),
      meta: {
        category: ['시스템관리', '접속정보'],
        authorization: ['ROLE_STORE', 'ROLE_ADMIN'],
      },
    },

    /* 시스템관리(시청) */

    // 1. 감면대상자 관리 조회
    {
      path: '/reductionlist',
      name: 'reductionlist',
      component: () => import('@/html/system/cityhall/ReductionList'),
      meta: {
        category: ['시스템관리(시청)', '감면대상자관리'],
        authorization: ['ROLE_ADMIN', 'ROLE_CITYHALL'],
      },
    },
    // 2. 감면대상자 관리 상세
    {
      path: '/reductionview',
      name: 'reductionview',
      component: () => import('@/html/system/cityhall/ReductionView'),
      meta: {
        category: ['시스템관리(시청)', '감면대상자관리'],
        authorization: ['ROLE_ADMIN', 'ROLE_CITYHALL'],
      },
    },
    // 3. 감면대상자 관리 등록
    {
      path: '/reductionregist',
      name: 'reductionregist',
      component: () => import('@/html/system/cityhall/ReductionRegist'),
      meta: {
        category: ['시스템관리(시청)', '감면대상자관리'],
        authorization: ['ROLE_ADMIN', 'ROLE_CITYHALL'],
      },
    },

    // Not Found Page
    {
      path: '/404',
      name: 'notFound',
      component: Error,
    },

    {
      path: '/:pathMatch(.*)*',
      redirect: '/404',
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else if (to.hash) {
      return {
        selector: to.hash,
        offset: { x: 0, y: 0 },
      };
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  const isLogin = store.state.isLogin;
  const authorCode = store.state.authorCode;
  const empDiv = store.state.empDiv;
  const { authorization, empcategory } = to.meta;

  if (to.matched.some(record => record.meta.authorization)) {
    if (authorCode === 'ROLE_ADMIN') {
      next();
      return;
    }
  }

  if (to.matched.some(record => record.meta.authorization)) {
    if (!isLogin) {
      openModal('로그인이 필요한 서비스입니다.', 'warning', '/ssomemberlogin');
      return;
    }

    if (!authorization.includes(authorCode)) {
      openModal('접근 권한이 존재하지 않습니다.', 'warning', '/main');
      return;
    }
  }

  if (to.matched.some(record => record.meta.empcategory)) {
    if (!empcategory.includes(empDiv)) {
      openModal('접근 권한이 존재하지 않습니다.', 'warning', '/main');
      return;
    }
  }

  if (to.matched.some(record => record.meta.onlyGuest)) {
    if (isLogin) {
      return next({ path: '/main' });
    }
  }

  next();
});
